@media (max-width: 1200px){

  .border-right-primary{
    border-right: unset !important;
  }
}

@media (max-width: 992px) {
  .team-details{
    &.to-right{
      h5{
        text-align: left;
        padding: 6px 10px;
        border-left: 8px solid #7CEBB6;
        border-right: unset;
        font-family: $font-main-title-bold;
      }
    }
    h5{
      text-align: left;
      padding: 6px 10px;
      border-left: 8px solid #7CEBB6;
      font-family: $font-main-title-bold;
    }
    p {
      font-family: $font-main-title;
      font-size: 18px;
      padding: 0 10px;
    }
  }
  //.card-hero{
  //  height: 350px;
  //}
  .features {
    height: auto;
    ul{
      height: auto;
    }
  }
  .h-200px{
    height: auto;
  }
  .h-180px{
    height: auto;
  }
  .h-70px{
    height: auto;
  }
  .top-team-img{
    height: 350px !important;
    img{
      object-fit: cover;
      height: 350px !important;
    }

  }
  .custom-select{
    width: 300px !important;
    margin-left: 2px !important;
  }
  .w-75{
    width: 100% !important;
  }
  .nav-link.active::before {
    background-color: transparent !important;
  }
  .text-header-centered h1, .text-header-centered .h1 {
    font-size: 30px;
    line-height: 35px;
  }
  .text-header-centered h5, .text-header-centered .h5 {
    font-size: 18px;
    line-height: 20px;

  }
  .main-h1{
    font-size: 18px !important;
    padding: 10px 0;
  }
  .border-right-primary{
    border-right: unset !important;
  }
  .bdr-md-r-dot{
    border-bottom: 2px dotted #ccc;
  }
  .titre-bas {
    width: 90% !important;
    text-align: left !important;
    left: 15px !important;
  }
  .explore h1{
    font-size: 30px;
    line-height: 34px;
  }

  .mt-lg-r{
    margin-top: 2.25rem !important;
  }
  .btn-black{
    width: 200px;
  }
  .btn-nav-primary{
    width: 200px;
  }
  .css-b62m3t-container{
    width: 200px;
  }

  .bg-top-home {
    height: 300px;
    object-fit: cover;
  }
  .bg-top-home img {
    height: 300px;
    object-fit: cover;
  }
  .layer-top{
    top:0;
    right: 0;
    img{
      width: 97px;
      height: 115px;
    }
  }
  .layer-top-left{
    bottom:0;
    left: 0;
    img{
      width: 97px;
      height: 115px;
    }
  }

  .text-header-centered h2 {
    font-size: 21px !important;
    line-height: 1.6rem !important;
  }

  .text-header-centered .type-writer-effect {
    font-size: 14px !important;
  }
  .bandeau_section h3 {
    font-size: 21px !important;
    //margin-top: 15% !important;
    line-height: 2rem !important;
    width: 100% !important;
  }
  .bg-cmarche {
    img{
      height: 175px !important;
      object-fit: contain !important;
    }
  }
}
@media (max-width: 767px) {
  .bandeau_section h3 {
    font-size: 21px !important;
    //margin-top: 19% !important;
    line-height: 2rem !important;
    width: 100% !important;
  }
  .p-box{
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
  }
  //.flip-card .box, .flip-card .box1 {
  //  width: 100%;
  //}
  //.flip-card {
  //  width: 100%
  //}
  .heading_container h2 {
    width: 90% !important;
    font-size: 18px !important;
  }
  .client_section .box .detail-box .bg-photo {
    height: 220px !important;
  }
  .work-together {
    margin-top: 0px !important;
  }
  .technologie_section .collright {
    width: 100% !important;
  }
  .politique-h {
    font-size: 27px !important;
    width: 100% !important;
    padding-top: 20px !important;
  }
  .technologie_section .collright .text-block {
    width: 100% !important;
    font-size: 15px !important;
  }
  .mobile-top-section {
    margin-top: 62px;
  }
  .equip-desktop {
    display: none;
  }
  .equipe-mobile {
    display: block !important;
  }
  .h-space-planning {
    width: 96% !important;
  }




  .why_section .row {
    margin-left: 0 !important;
    margin-right:  0 !important;
  }
  .why_section .row .col-md-3 {
    width: 100% !important;
    display: flex  !important;
    justify-content: center  !important;
  }
  .work-together-bottom div {
    width: 100% !important;
  }
}


@media (max-width: 500px) {
  .bandeau_section h3 {
    font-size: 21px !important;
    //margin-top: 25% !important;
    line-height: 2rem !important;
    width: 100% !important;
  }
}
.bandeau_section h3{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}