$breakpoint-1199: 1199px;
$breakpoint-991: 991px;
$breakpoint-767: 767px;
$breakpoint-575: 575px;

$font-main-title : "SpaceGrostesk Regular" , serif;
$font-main-title-bold:"SpaceGrotesk Bold" , serif;


$base-fontsize: 16;
$error-text-color: #dc3545;
$primary: #7CEBB6;;
$text-color: #161411;
$secondary-color: #ffffff;



$primary-color: #7CEBB6;




$sizes: (
        '0'    : 0,
        '3'    : 3,
        '7'    : 7 ,
        '10'   : 10,
        '11'   : 11,
        '12'   : 12,
        '13'   : 13,
        '14'   : 14,
        '15'   : 15,
        '16'   : 16,
        '17'   : 17,
        '18'   : 18,
        '19'   : 19,
        '20'   : 20,
        '22'   : 22,
        '24'   : 24,
        '25'   : 25,
        '27'   : 27,
        '28'   : 28,
        '30'   : 30,
        '33'   : 33,
        '34'   : 34,
        '35'   : 35,
        '36'   : 36,
        '38'   : 38,
        '40'   : 40,
        '45'   : 45 ,
        '46'   : 46 ,
        '50'   : 50,
        '54'   : 54,
        '55'   : 55,
        '60'   : 60,
        '65'   : 65 ,
        '70'   : 70 ,
        '75'   : 75 ,
        '80'   : 80 ,
        '85'   : 85 ,
        '90'   : 90 ,
        '100'  : 100,
        '110'  : 110,
        '115'  : 115,
        '120'  : 120,
        '130'  : 130,
        '140'  : 140,
        '150'  : 150,
        '160'  : 160,
        '170'  : 170,
        '180'  : 180,
        '190'  : 190,
        '200'  : 200,
        '240'  : 240,
        '250'  : 250,
        '300'  : 300,
        '340'  : 340,
) !default;

$font-families : (
        'title-regular' : $font-main-title,
        'text-bold'  : $font-main-title-bold,



) !default;

@each $name, $value in $sizes {
    .w-#{$name}px{
        width: #{$value}px;
    }
    .mt-#{$name} {
        margin-top: #{$value}px;
    }
    .mb-#{$name} {
        margin-bottom: #{$value}px;
    }
    .mr-#{$name} {
        margin-right: #{$value}px;
    }
    .ml-#{$name} {
        margin-left: #{$value}px;
    }

    .m-#{$name} {
        margin: #{$value}px;
    }
    .p-#{$name} {
        padding: #{$value}px;
    }
    .pl-#{$name} {
        padding-left: #{$value}px;
    }
    .pr-#{$name} {
        padding-right: #{$value}px;
    }

}




