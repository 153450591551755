.text-header-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: white;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 999;
}

.text-header-centered h2 {
  font-size: 52px;
  line-height: 57px;
  color: $secondary-color;
}

.text-header-centered h1 {
  font-size: 40px;
  line-height: 44px;
  color: $secondary-color;
}

.text-header-centered h5 {
  font-size: 23px;
  line-height: 30px;
  color: $secondary-color;
}


.hero-description {
  color: #FFF;
  font-family: $font-main-title;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 10px;
}

//.text-header-centered .type-writer-effect {
//  font-size : 1.5rem;
//  padding-top:12px;
//}
.text-header-centered .type-writer-effect .writer {
  color: $primary-color;
}

.bandeau_section {
  background-image: url("/assets/images/bandeau.jpg");
  background-size: cover;
  background-position: center;
  height: 353px;
  position: relative;
}

.bandeau_section h3 {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  width: 95%;
  //margin: 6% auto 0;
}

.bandeau_section h3 span {
  display: block;
  color: $primary-color;
  margin-top: 30px;
}

.client_section .bg-equipe1 .box .detail-box .left {
  float: left;
}

.client_section .bg-equipe1 .box .detail-box .right {
  float: right;
}

.client_section .bg-equipe1 .box .detail-box {
  margin: 0;
}

.client_section .bg-equipe1 .box .detail-box .bg-photo {
  background: none;
  height: auto;
}

.client_section .bg-equipe1 .box .detail-box .bg-photo img {
  position: relative;
  width: 100%;
  border: 1px solid #999
}

.client_section .bg-equipe1 .box .detail-box .desc-photo h5 {
  text-align: left;
  padding: 6px 10px;
  border-left: 8px solid #7CEBB6;
}

.client_section .bg-equipe1 .box .detail-box .desc-photo.text-right h5 {
  float: right;
  text-align: right;
  padding: 6px 10px;
  border-right: 8px solid #7CEBB6;
  border-left: none;
  margin-bottom: 20px;
}

.client_section .bg-equipe1 .box .detail-box .desc-photo.text-right p {
  clear: both;
  text-align: left;
}

.client_section .bg-equipe1 .box .detail-box .desc-photo h5 span {
  color: #7CEBB6;
}

.bg-equipe11 .img-equipe1, .bg-equipe11 .img-equipe2, .bg-equipe11 .img-equipe3, .bg-equipe11 .img-equipe4, .bg-equipe11 .img-equipe5, .bg-equipe11 .img-equipe6 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10000;
}

.bg-equipe1 .bg-equipe11.bg-img .img-equipe1 {
  left: 0;
}

.bg-equipe1 .bg-equipe11.bg-img2 .img-equipe1 {
  bottom: 0;
  top: auto;
}

.bg-equipe1 .bg-equipe11.bg-img3 .img-equipe1 {
  left: 0;
  top: 20%;
}

.bg-equipe1 .bg-equipe11.bg-img4 .img-equipe1 {
  bottom: 0;
  top: auto;
  left: 0
}

.bg-equipe1 .bg-equipe11.bg-img5 .img-equipe1 {
  right: 0;
  top: 15%;
}

.bg-equipe11 .img-equipe2 {
  left: 0 !important;
  top: 30% !important;
}

.bg-equipe11 .img-equipe3 {
  top: 20% !important;
}

.bg-equipe11 .img-equipe4 {
  left: 0;
  top: 60% !important;
}

.bg-equipe11 .img-equipe5 {
  top: 30% !important;
}

.bg-equipe11 .img-equipe6 {
  left: 0;
  top: 50% !important;
}

.layout_padding.activity.equipe .container-fluid {
  padding: 0;
}

.layout_padding.activity.equipe .carousel-inner {
  border-bottom: 1px dotted #999;
}

.bg-equipe11 .img-equipe1 img, .bg-equipe11 .img-equipe2 img, .bg-equipe11 .img-equipe3 img, .bg-equipe11 .img-equipe4 img, .bg-equipe11 .img-equipe5 img, .bg-equipe11 .img-equipe6 img {
  width: 120px;
  height: 120px;
}

.bg-equipe22 img {
}

.client_section.licence .box .detail-box .desc-photo ul {
  padding: 0;
}

.client_section.licence .box .detail-box .desc-photo ul li {
  list-style: none;
  text-align: left;
  border-bottom: 1px solid #efefef;
  padding: 10px 0;
  font-size: 14px;
}

.client_section.licence .box .detail-box .desc-photo ul li .check {
  position: absolute;
  right: 3px;
}

.client_section.licence .box .detail-box .desc-photo ul li .check img {
  width: 100%;
  position: relative;
  max-height: 18px;
  top: 0;
  left: 0;
}

.client_section.layout_padding.activity.techno ul {
  list-style: none;
  padding: 0;
  margin: 30px 0 0 0;
}

.client_section.layout_padding.activity.techno ul li {
  float: left;
  width: 48%;
  padding: 1%;
  min-height: 185px;
}

.client_section.layout_padding.activity.techno ul li:not(:nth-child(7)):not(:nth-child(8)) {
  border-bottom: 2px dotted #ccc;
}

.block-exploitez {
  text-align: center;
  padding: 40px 0;
  float: left;
}

.block-exploitez span {
  display: block;
}

.block-exploitez span img {
  width: 40%;
}

.block-exploitez .titre-desc {
  font-size: 16px;
}

.client_section.layout_padding.activity.techno ul li.odd {
  border-right: 2px solid #7CEBB6;
}

.client_section.layout_padding.activity.techno ul li .list-tech {
  float: left;
  width: 75%;
}

.client_section.layout_padding.activity.techno ul li .list-tech span {
  font-weight: bold;
  width: 100%;
}

.client_section.layout_padding.activity.techno ul li span {
  display: block;
  float: left;
  width: 20%;
  margin-right: 20px;
}

.client_section.layout_padding.activity.techno ul li span img {
  width: 100%;
}

.text-vert-primary {
  color: $primary-color;
}
.f-bold{
  font-family: $font-main-title-bold;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.heading_container h2 {
  position: relative;
  margin-bottom: 0;
  font-size: 1.5rem;
  background-color: #7CEBB6;
  padding: 8px 20px;
  width: 70%;
  text-transform: uppercase;
  font-weight: 600;
}

.heading_container.heading_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 40px 0 40px;

}

.img-potentiel {
  min-height: 186px !important;
}

.bg-cmarche {
  background-color: #F6F6F6;
  background-image: url("/assets/images/slider/background.svg");
  background-size: cover;
  background-position: center;
  height: auto;

  img {
    height: 540px;
    object-fit: contain;
  }
}

.carousel-indicators {
  display: none;
}

.layout_padding {
  padding: 70px 0;
}

.why_section {
  background-image: url("/assets/images/bg-porquoi.png");
  margin-top: 15px;
}

.why_section .box {
  text-align: center;
  margin-top: 45px;
  background-color: #161411;
  padding: 65px 20px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #e5e5e5;
  min-height: 232px;
  width: 250px;
}

.box1 {
  background: white;
  padding: 20px 10px;
  margin-top: 45px;
  min-height: 205px;
  font-size: 11px;
  display: flex;
  min-height: 220px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  transform: rotateY(180deg);
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 232px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.why_section .box .img-box {
  margin-bottom: 15px;
}

.why_section .box .img-box svg {
  width: 55px;
  height: auto;
  fill: #ffffff;
}

.flip-card .box, .flip-card .box1 {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.detail-box h5 {
  font-size: 2rem;
  font-weight: 700;
  color: #7CEBB6;
}


.arrival_section.video {
  background: white;
}

.arrival_section.bg-activate {
  background-color: #d4ced0;
  background-image: url("/assets/images/bg-activite.jpg");
  background-size: cover;
  background-position: center;
  height: 278px;
}

.arrival_section.bg-activater {
  background-color: #161411;
  background-image: url("/assets/images/activiter.png");
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  min-height: 176px;
}

.arrival_section.bg-activater.bg-societe {
  background-image: url("/assets/images/header_equipe.png");
  height: 450px;
}

.arrival_section.bg-activater.bg-societe .arrival_bg_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.arrival_section.bg-activater.bg-societe h3 {
  text-transform: capitalize;
  margin-top: 0;
  font-size: 2.5rem;
  line-height: 3.5rem;
}

.arrival_section.bg-activater.bg-societe p {
  padding-top: 33px;
}

.arrival_section.bg-activater.bg-societe h3 span {
  color: #7CEBB6;
}

.arrival_section.bg-activate h3 {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

.arrival_section.bg-activater h3 {
  color: white;
  margin-top: 45px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.arrival_section.bg-activater.licence h3 {
  text-transform: none;
}

.arrival_section.bg-activater.licence h3 select {
  background: none;
  border-bottom: 1px solid white !important;
  min-width: 28%;
  border: none;
  color: #7CEBB6;
  margin-left: 14px;
}

.arrival_section.bg-activater.licence h3 select option {
  background: none;
  border-bottom: 1px solid #ccc !important;
  padding: 9px 20px;
  color: #555;
  font-size: 20px;
}

.arrival_section.bg-activater.licence h3 select option:hover {
  background: white;
}

.work-together .demande-demo {
  width: 100%;
  padding: 14px 0;
  font-size: 16px;
}

.work-together .container {
  display: flex;
}

.work-together .arrival_bg_box {
  float: left;
}

.work-together .work-together-bottom {
  float: left;
  width: 50%;
}

.arrival_section .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.arrival_section.bg-activate p {
  color: white;

}

.demande-demo {
  background: #7CEBB6;
  border-radius: 3px;
  color: #000;
  border: none;
  padding: 8px 0px;
  margin-right: 10px;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

footer {
  background: #fff;
  padding: 40px 0 10px;

}
html{
  background: #F6F6F6;
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bdr-btm{
  border-bottom: 1px solid #e5e5e5;
}
.logo_footer {
  margin-bottom: 30px;
}

.information_f p {
  margin-bottom: 1.2rem;
  line-height: 25px;
  font-size: 15px;
}

.widget_menu h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  margin-bottom: 25px;
}

.widget_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  ont-size: 14px;
  color: #000;
  margin-bottom: 0;
  float: left;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.widget_menu {
  float: right
}

.widget_menu ul li {
  float: left;
  padding: 0 25px;


}

.text-primary-color {
  color: $primary-color;
}

.widget_menu ul li a {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  float: left;
  width: 100%;
  margin-top: 5px;
  text-align: center;
  font-weight: 600;
  &:hover{
    text-decoration: underline !important;
  }
}

.cpy_ {
  background: white;
  text-align: center;
  padding: 30px 0 70px;
  height: 85px;
  a{
    &:hover{
      text-decoration: underline !important;
    }
  }
}

.cpy_ .widget_menu {
  //float: none;
}

.cpy_ .widget_menu ul li {
  //width: 33%;
  padding: 0
}

.cpy_ .widget_menu ul li a {
  font-size: 12px;
}

.cpy_ .widget_menu ul li a.text-align-right {
  text-align: right;
}

.cpy_ p {
  margin: 0;
  color: #333;
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  padding-left: 20px;
  float: left;
  font-family: $font-main-title;
}

.cpy_ p a {
  margin: 0;
  color: #333;
  font-size: 15px;
  font-weight: 400;
}

.w-5 {
  width: 5%;
}

.w-49 {
  width: 49%;
}

.w-44 {
  width: 44%;
}
.float-right{
  float: right;
}
.css-lmdiq5-menu{
  z-index: 999999999999;
}
.css-1mdiq5-menu{
  z-index: 999999999999;
}
.lft-top{
  top:0;
  left: 0;
  img{
    width: 120px;
    height: 120px;
  }
}

.rgt-top{
  top:0;
  right: 0;
  img{
    width: 120px;
    height: 120px;
  }
}