.main-h1{
  color: #161411;
  text-align: center;
  font-family: $font-main-title;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.p-common{
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.space-p{
  color: #161411;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span{
    font-size: 20px;
    font-weight: 600;
  }
}

.border-dark{
  border: 1px solid #161411;
}
.btn:disabled{
  border: unset !important;
}


.inline-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: $font-main-title;
  line-height: normal;
  text-transform: uppercase;
}

.inline-title:before,
.inline-title:after {
  content: "";
  width: 100%;
  height: 2px;
  background: #000;
}

.inline-title:before{
  margin: 0 10px 0 0;
}

.inline-title:after{
  margin: 0 0 0 10px;
}
.sm-txt{
  color: $text-color;
  text-align: center;
  font-family: $font-main-title;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title-price{
  font-size: 18px;
  font-style: normal;
  //font-weight: 700;
  font-family: $font-main-title;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;

  small{
    text-transform: none;
  }
}

.text-licence {
  background-color: #efefef;
  padding: 10px 5px;
  padding-top: 10px;
  padding-right: 5px;
  padding-bottom: 10px;
  padding-left: 5px;
  margin: 5px 0;
  text-align: center;
  color: #333;
  font-size: 10px;
}
.size-14-weight400 {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.fs-12{
  font-size: 12px;
}
.fw-normal{
  font-weight: normal;
  font-family: $font-main-title;
}
.slidecontainer {
  width: 90%;
  border-radius: 4px;
  padding: 10px;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
//input[type="range"] {
//  accent-color: coral;
//}
input[type="range"] {
  -webkit-appearance: none;
  //margin-right: 15px;
  width: 100%;
  height: 7px;
  border-radius: 5px;
  background-size: 70% 100%;
  background-repeat: no-repeat;
  accent-color:black;
}
//
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #7CEBB6;
  transition: background .3s ease-in-out;
  border: 3px solid #fff;
}

input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;

}


.metrage {

  color: #161411;
  font-family: $font-main-title;
  font-size: 10px;
  display: inline-block;
  text-align: left;
  float: left;
}
.f-t-b{
  color: #161411;
  font-family: $font-main-title;
  font-size: 10px;
}
input[type="range" i] {
  appearance: auto;
  cursor: default;
  color: #7CEBB6 !important;
  padding: initial;
  border: initial;
  margin: 2px;
}


.in-check{
 top:9px;
}
.tooltip-container{
  color: #CCC;
}
.fs-text-bold{
  font-family: $font-main-title-bold;
}

.secondary-h1{
  margin-bottom: 20px;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.fs-16{
  font-size: 16px;
}
.fs-14{
  font-size: 14px;
}
.offer-text{
  color: #FFF;
  text-align: center;
  font-family: $font-main-title;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.area-text{
  padding: 3px 13px;
  color: #000;
  font-family: $font-main-title-bold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dark-txt{
  color: #000;
  font-family: $font-main-title-bold;
  font-size: 16px;
  font-style: normal;
}
.area-bg{
  background: #C9E2FF;
}
.text-underlined{
  text-decoration: underline;
}
fs-40{
  font-size:40px
}
