@font-face {
  font-family: "SpaceGrostesk Regular";
  src:  url("../../fonts/SpaceGrotesk-Regular.ttf") format("opentype");
  src:  url("../../fonts/SpaceGrotesk-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SpaceGrotesk Bold";
  src:  url("../../fonts/SpaceGrotesk-Bold.ttf") format("opentype");
  src:  url("../../fonts/SpaceGrotesk-Bold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
