.navbar-nav{
  .navbar-brand{
    img{
      width: 200px;
    }
  }

  .nav-link{
    padding: 5px 10px !important;
    color:$text-color;
    text-align: center;
    text-transform: capitalize;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 14px;
    font-family: $font-main-title;
    &.active{
      color: $primary-color;
      font-weight: 700;
    }
  }
}
.menu-disabled{
  pointer-events: none;
  background-color: unset !important;
  color: unset !important;
  opacity: 0.5;
  font-weight: normal;
}
.css-whihww-control{
  border: unset !important;
}

.nav-link.active{
  position: relative;
}
.nav-link.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: $primary-color;
  top: -73%;
  left: 0;
}
.navbar-toggler{
  border: unset !important;
}

/* Style for the navbar to stick to the top */
.navbar {
  position: sticky;
  top: 0;
  z-index: 111000; /* Adjust this value to make sure the navbar appears above other elements */
}
