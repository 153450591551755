.custom-table{
  table {
    font-family: $font-main-title;
    border-collapse: collapse;
    width: 100%;
  }

  td, th {
    border: 1px solid #161411;

    padding: 8px;
    text-align: center;
  }

  tr:first-child{
    background-color:white;

  }
  tr:first-child th {
    font-size: 16px;
    font-weight: 500;
  }
  tr:not(:first-child) {
    background-color:transparent;
    font-size: 16px;
    font-weight: 400;
  }
}
.highlighted{
  transform: scale(1.01);
  box-shadow: 10px 10px 0 0 #282c34;
}
.h-200px{
  height: 200px;
}
.h-180px{
  height: 180px;
}
.h-140px{
  height: 140px;
}
.h-130px{
  height: 130px;
}
.h-70px{
  height: 70px;
}
.h-35px{
  height: 35px;
}
.h-25px{
  height: 25px;
}
.h-23px{
  height: 23px;
}
.h-215px{
  height: 215px;
}
.h-10px{
  height: 10px;
}