.arrival_section .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.arrival_section.video {
  background: white;
}

