input, textarea {
    outline: none !important;
}

* {
    outline: none !important;
}

html {
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
    //background: $white;

}

body {
    font-size: 15px;
    line-height: 22px;
    //margin-bottom: 590px;

    font-family: $font-main-title;

    @media (max-width: $breakpoint-1199) {
        margin-bottom:0;
    }

}

body, html {
    padding: 0;
}

.h-185px{
    height: 185px;
}




h1, h2, h3 {

}

a {
    color: inherit;
    text-decoration: none;
}

a:hover, a:focus {
    color: inherit;
}

textarea {
    resize: none;
}

.w-100 {
    width: 100%;
}

.text-center-991 {
    @media (max-width: $breakpoint-991) {
        text-align: center;
    }
}

.hide-767 {
    @media (max-width: $breakpoint-767) {
        display: none;
    }
}

.show-767 {
    display: none;

    @media (max-width: $breakpoint-767) {
        display: inline-block;
    }
}

.row-eq-height {
    @media (min-width: 992px) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

.mt-lg-r{
    margin-top: 4rem;
}

.mt-md-r{
    margin-top: 5rem;
}

.border-right-primary{
    border-right: 4px solid $primary-color;
}
.border-left-primary{
    border-left: 2px solid $primary-color;
}
.border-dot{
  border-bottom:  2px dotted #ccc;
}
.placeholder-select{
    color: white;
    margin-top: 45px;
    text-align: center;
    font-size: 40px !important;
    font-weight: 400;
}

.custom-select {
    position: relative;
    width: 400px;
    text-align: start;
    margin-top: 41px;
    margin-left: 21px;
}

.selected-option {
    border-bottom: 1px solid #ccc; /* Border style for the select */
    padding: 8px;
    cursor: pointer;
    color: $primary-color;
    text-align: center;
    font-size: 18px;
}

.arrow {
    position: absolute;
    top: 100%;
    right: 8px;
    transform: translateY(-50%);
    color: $primary-color;
}
.arrow::after{

    content: "";
    height: 0;
    border: 11px solid transparent;
    border-color: #7CEBB6 transparent transparent transparent;
    z-index: 9999;
}


.options {
    position: relative;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    color: $text-color;
    font-family: $font-main-title;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 8px;
    padding-bottom: 8px;

}

.option {
    padding: 0 8px;
    cursor: pointer;
}

.option-divider {
    border: none;
    border-bottom: 1px solid #ccc;
}
.select-selected:after {
    position: absolute;
    content: "";
    top: 11px;
    right: 5px;
    height: 0;
    border: 11px solid transparent;
    border-color: #7CEBB6 transparent transparent transparent;
    z-index: 9999;
}

.select-selected.select-arrow-active:after {
    border-color: transparent transparent #7CEBB6 transparent;
    top: -4px;
}
.text-transparent{
    color: transparent !important;
}
.features{
    height: 600px;
    background: white;
    ul{
        list-style: none;
        padding-left: 9px;
        height: 445px;
       li {
           position: relative;
            list-style: none;
            text-align: left;
            border-bottom: 1px solid #efefef;
            padding: 10px 3px 10px 0;
            font-size: 12px;

          .check {
               position: absolute;
               right: 3px;
               img {
                  width: 100%;
                  position: relative;
                  max-height: 18px;
                  max-width: 18px;
                  top: 0;
                  left: 0;
              }
           }
        }
    }
}
.card-hero{
    height: 238px;
}
.bdr-all-dark{
   border: 1px solid #999;
}
.team-details{
    &.to-right{
        h5{
            text-align: right;
            padding: 6px 10px;
            border-right: 8px solid #7CEBB6;
            border-left: none;
            margin-bottom: 20px;
        }
    }
    h5{
        text-align: left;
        padding: 6px 10px;
        border-left: 8px solid #7CEBB6;
        font-family: $font-main-title-bold;
    }
    p {
        font-family: $font-main-title;
        font-size: 18px;
        padding: 0 10px;
    }
}
.calendly-container {
    /* Custom styles for the container */
    //width: 100%;
    //max-width: 100%; /* Example maximum width */
    //margin: 0 auto;
    //height: 630px/* Center the container horizontally */
}

.calendly-container iframe {
    /* Custom styles for the Calendly iframe */
    border: none; /* Remove iframe border */
}
.calendly-container .calendly-inline-widget button {
    /* Custom styles for the button */
    background-color: #007bff;
    color: #ffffff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    font-family: $font-main-title;
}

.cursor-pointer {
    cursor: pointer;
}
.h-400px{
    height: 400px;
}